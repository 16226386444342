<template>
    <div class="login">
        <div class="login-cont">
            <div class="login-text">
                <h1>Welcome</h1>
                <p>欢迎您，</p>
                <p>登录教务管理控制台，</p>
                <p>开启你的音乐之旅！</p>
                <div class="login-input">
                    <h2>账户密码登录</h2>
                    <div class="login-input-text">
                        <img src="../assets/img/souji.png" alt="" />
                        <input v-model="loginForm.account" type="text" placeholder="请输入登录账号">
                    </div>
                    <div class="login-input-text">
                        <img src="../assets/img/mima.png" alt="" />
                        <input v-model="loginForm.password" type="password" placeholder="请输入登录密码">
                    </div>
                    <div class="login-input-but">
                        <button :disabled="loading" @click="handleLogin">{{ loading? '登录中...': '登录' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { adminInfo, login } from '@/apis/common'

export default {
    name: 'Login',
    data() {
        return {
            loginForm: {
                account: '',
                password: ''
            },
            loading: false,
        }
    },
    watch: {
        $route: {
            handler(route) {
                console.log(route)
            },
            immediate: true
        }
    },
    created() {

    },
    mounted() {
        // 监听回车
        document.onkeydown = (e) => {
            if (e.keyCode === 13) {
                this.handleLogin()
            }
        }
    },
    destroyed() {

    },
    methods: {
        handleLogin() {
            this.loading = true
            login(this.loginForm).then(res => {
                window.title = res.data.data.agency_school_name
                sessionStorage.setItem('user',JSON.stringify(res.data.data))
                this.loading = false
                this.$router.push('/')
            }).catch(err => {
                this.loading = false
            })
        }
    }
}
</script>

<style>
.login{
    background: url("../assets/img/login-back.png") no-repeat center center;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
.login-cont{
    width: 1100px;
    height: 600px;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background-color: rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
}
.login-text{
    width: 80%;
    height: 350px;
    margin: 0 auto;
    background: linear-gradient(90deg, #5389E9, #9ED8DB);
    border-radius: 5px;
    color: #fff;
    padding: 20px 40px;
    box-sizing: border-box;
    line-height: 40px;
    position: relative;
}
.login-text>h1{
    font-size: 60px;
    padding-bottom: 30px;
    position: relative;
    margin: 40px 0;
}
.login-text>h1:after{
    content: '';
    display: block;
    width: 80px;
    height: 6px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 10px;
}
.login-text>p{
    font-size: 30px;
    width: 300px;
    margin: 0;
}
.login-input{
    position: absolute;
    top: -60px;
    right: 0;
    z-index: 1;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    padding: 40px;
}
.login-input>h2{
    font-size: 24px;
    color: #5386E9;
    text-align: center;
    padding-bottom: 30px;
    position: relative;
}
.login-input>h2:after{
    content: '';
    background-color: #C9D5FC;
    height: 10px;
    width: 142px;
    position: absolute;
    bottom: 33px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}
.login-input-text{
    margin-top: 60px;
    width: 400px;
    border-bottom: 1px solid #CCCCCC;
    position: relative;
}
.login-input-text>img{
    width: 22px;
    height: 22px;
    position: absolute;
    top: 10px;
    left: 6px;
    z-index: 1;
    object-fit: contain;
}
.login-input-text>input{
    width: calc(100% - 40px);
    height: 40px;
    padding-left: 40px;
    border: none;
    outline: none;
}
.login-input-but{
    margin-top: 40px;
    text-align: center;
}
.login-input-but>button{
    width: 400px;
    height: 60px;
    color: #fff;
    border-radius: 5px;
    font-size: 26px;
    background: linear-gradient(90deg, #5389E9, #9ED8DB);
    border: none;
    outline: none;
    cursor: pointer;
}
</style>
